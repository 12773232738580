import axiosInstance from "./api";
import TokenService from "./token.service";
import Swal from "sweetalert2";

const setup = () => {
  
  const modalToken = (title, text, icon) => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      title,
      text,
      icon,
      confirmButtonColor: "#002647",
      confirmButtonText: "Aceptar",
    }).then(() => {
      TokenService.removeUser();
      window.location = '/login'
    });
  };

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["access-token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signinWeb" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            
            const { accessToken } = rs.data.body;

            TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            modalToken('¡Sesión Expirada!', 'Su sesión ha expirado, por favor vuelva a iniciar sesión', 'warning')
          }
        } else if (err.response.status === 401 && originalConfig._retry) {
          modalToken('¡Sesión Expirada!', 'Su sesión ha expirado, por favor vuelva a iniciar sesión', 'warning')
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
