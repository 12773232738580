<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row justify-content-between">
        <div class="col-6">
          <h6>Lista de Usuarios</h6>
        </div>

        <div class="col-6">
          <div class="row justify-content-end">
            <div class="col-6 col-md-auto" style="text-align: right !important">
              <button
                type="button"
                class="btn btn-success mb-0"
                @click="openModalAddUser = true"
              >
                <i class="fas fa-user-plus"></i> Agregar
              </button>
            </div>

            <div class="col-6 col-md-auto" style="text-align: right !important">
              <button
                type="button"
                class="btn btn-secondary mb-0"
                @click="download"
                :disabled="downloadLoading"
              >
                <i
                  v-if="downloadLoading"
                  class="fas fa-circle-notch fa-spin"
                ></i>
                <i v-else class="fas fa-download"></i> Descargar
              </button>
            </div>

            <div class="col-6 col-md-auto" style="text-align: right !important">
              <button
                type="button"
                class="btn btn-warning text-dark mb-0"
                @click="downloadCSV"
                :disabled="downloadCSVLoading"
              >
                <i
                  v-if="downloadCSVLoading"
                  class="fas fa-circle-notch fa-spin"
                ></i>
                <i v-else class="fas fa-download"></i> CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="container-fluid">
        <div v-if="!loading" class="row">
          <div class="col-12 text-left mb-2">
            <Tooltip tooltipText="Inactivar Usuario Masivo" position="right">
              <button
                :disabled="isCheck"
                type="button"
                @click="inactivarOrActivarMassivo(2)"
                class="btn btn-light btn-sm mx-1 mb-0 text-dark"
              >
                <span class="fas fa-user-alt-slash"></span>
              </button>
            </Tooltip>

            <Tooltip tooltipText="Activar Usuario Masivo" position="right">
              <button
                :disabled="isCheck"
                type="button"
                @click="inactivarOrActivarMassivo(1)"
                class="btn btn-success btn-sm mx-1 mb-0 text-light"
              >
                <span class="fas fa-user-check"></span>
              </button>
            </Tooltip>
          </div>

          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Acciones
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Chequeo
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Estado
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Perfil
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Nombre
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      No. de Identifcación
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Usuario
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Banco
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Sucursal
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Teléfono
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Correo
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Fecha de Creación
                    </th>
                  </tr>
                </thead>
                <tbody v-if="displayedPosts.length">
                  <tr v-for="item of displayedPosts" :key="item.id">
                    <td class="text-center">
                      <Tooltip
                        tooltipText="Actualizar Usuario"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="modalUpdateInfo(item)"
                          class="btn btn-info btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-user-edit"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        tooltipText="Actualizar Credenciales"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="modalUpdateCredential(item)"
                          class="btn btn-secondary btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-user-lock"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="
                          [1, 3, 6].includes(getUser.idRol) &&
                          [2, 4].includes(item.rolId)
                        "
                        tooltipText="Actualizar Sucursal"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="modalUpdateSucur(item)"
                          class="btn btn-warning btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="far fa-building"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="item.idEstado == 1 && item.rolId != 1"
                        tooltipText="Inactivar Usuario"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="changeStatus(item, 2)"
                          class="btn btn-light btn-sm px-2 mx-1 mb-0 text-dark"
                        >
                          <span class="fas fa-user-alt-slash"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="item.idEstado != 1 && item.rolId != 1"
                        tooltipText="Activar Usuario"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="changeStatus(item, 1)"
                          class="btn btn-success btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-user-check"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="item.rolId != 1 && item.idEstado != 17"
                        tooltipText="Eliminar Usuario"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="changeStatus(item, 17)"
                          class="btn btn-danger btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-user-times"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="
                          [1].includes(getUser.idRol) && item.enviarOtp == 0
                        "
                        tooltipText="Activar OTP"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="changeStatusOtp(item, 1)"
                          class="btn btn-success btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-key"></span>
                        </button>
                      </Tooltip>

                      <Tooltip
                        v-if="
                          [1].includes(getUser.idRol) && item.enviarOtp == 1
                        "
                        tooltipText="Inactivar OTP"
                        position="right"
                      >
                        <button
                          type="button"
                          @click="changeStatusOtp(item, 0)"
                          class="btn btn-danger btn-sm px-2 mx-1 mb-0 text-light"
                        >
                          <span class="fas fa-key"></span>
                        </button>
                      </Tooltip>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <div class="form-check" v-if="item.rolId != 1">
                        <input
                          class="form-check-input mx-auto"
                          style="margin-left: 18% !important"
                          type="checkbox"
                          v-model="item.check"
                        />
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ statusName(item.idEstado) }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.rolNombre }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.nombreUsuario }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.documento }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.usuario }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.nombreBanco }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.nombreSucursal }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.celularUno }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.emailCorporativo }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ formatDate(new Date(item.fechaCreacion)) }}
                      </h6>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center">
                      <h6 class="mb-0 text-sm">Sin resultados.</h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 d-flex">
            <v-pagination
              class="ms-md-auto my-3"
              v-model="page"
              :pages="pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="page"
            />
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span
              v-if="loading"
              class="fas fa-circle-notch fa-spin fa-2x mb-4"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalUpdateUser
    :isOpen="openModal"
    :user="userCurrent"
    @close-modal="closeModal"
  />

  <ModalUpdateSucursal
    :isOpen="openModalSucursal"
    :user="userCurrent"
    @close-modal="closeModalSucursal"
  />

  <ModalUpdateCredentials
    :isOpen="openModalUser"
    :user="userCurrent"
    @close-modal="closeModalCredential"
  />

  <ModalAddUser
    :isOpen="openModalAddUser"
    :user="getUser"
    @close-modal="closeModalAdd"
  />
</template>

<script>
import { ref, toRefs, computed, reactive, watch } from 'vue';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import Tooltip from '@/components/Tooltip.vue';
import { API_BASEURL } from '@/helpers/config';
import axiosInstance from '@/services/api';
import {
  formatteNumber,
  downloadBlob,
  formatDate,
  getCurrentFormattedDate,
  removeDiacritics,
} from '@/utils/functions';
import Swal from 'sweetalert2';
import ModalUpdateUser from './ModalUpdateUser.vue';
import ModalUpdateCredentials from './ModalUpdateCredentials.vue';
import ModalUpdateSucursal from './ModalUpdateSucursal.vue';
import ModalAddUser from './ModalAddUser.vue';
import { useStore } from 'vuex';
import useEventsBus from '@/helpers/eventBus';

export default {
  name: 'projects-card',

  props: {
    userList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    perPages: {
      type: Number,
      default: 10,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    VPagination,
    Tooltip,
    ModalUpdateUser,
    ModalUpdateCredentials,
    ModalUpdateSucursal,
    ModalAddUser,
  },

  setup(props, { emit }) {
    const store = useStore();

    const { bus } = useEventsBus();

    const { getUser } = store.getters;

    const { perPages, filter } = toRefs(props);
    const page = ref(1);
    const url = ref(API_BASEURL);
    const openModal = ref(false);
    const openModalUser = ref(false);
    const openModalSucursal = ref(false);
    const openModalAddUser = ref(false);
    const userCurrent = reactive({});
    const downloadLoading = ref(false);
    const downloadCSVLoading = ref(false);
    const currentFilter = reactive(filter.value);

    const descriptionRoles = {
      1: 'Administrador de la herramienta',
      2: 'Registrar solicitudes desde el App Alala',
      3: 'Administrador de la herramienta a nivel banco',
      4: 'Administrador de una sucursal',
      5: 'Administrador de la App',
      6: 'Administra los usuarios de la herramienta',
      7: 'Administrador Sucursal a Nivel Nacional',
      8: 'Mesa de Ayuda',
      9: 'Mesa de Ayuda de la herramienta asistida',
    };

    const paginate = (data) => {
      let _page = page.value;
      let _perPage = perPages.value;
      let _from = _page * _perPage - _perPage;
      let _to = _page * _perPage;
      return data.slice(_from, _to);
    };

    const displayedPosts = computed(() => {
      return paginate(props.userList);
    });

    const isCheck = computed(() => !displayedPosts.value.some((i) => i.check));

    const inactivarOrActivarMassivo = (typeOperation) => {
      if (displayedPosts.value.some((i) => i.check)) {
        const usersId = displayedPosts.value.flatMap((i) =>
          i.check ? i.id : []
        );

        Swal.fire({
          title: `¿Está seguro de ${
            typeOperation == 2 ? 'Inactivar' : 'Activar'
          } los usuarios seleccionados?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return new Promise((resolve, reject) => {
              try {
                axiosInstance
                  .post(`/users/changeStatus/masive/`, {
                    typeOperation,
                    usersIds: usersId,
                    userId: getUser.userId,
                  })
                  .then(() => {
                    emit(`updateList`, true);
                    resolve(true);
                  })
                  .catch((err) => {
                    if (err.response.status === 401) return;

                    Swal.fire({
                      title: 'Inconveniente en el sistema, intente más tarde',
                      icon: 'warning',
                      confirmButtonText: 'Aceptar',
                    });
                  });
              } catch (e) {
                reject(false);
              }
            });
          },
        });
      } else {
        Swal.fire({
          text: `Es necesario chequear mínimo un registro.`,
          icon: 'warning',
          confirmButtonText: 'Aceptar',
        });
      }
    };

    const changeStatus = (item, status) => {
      Swal.fire({
        title: `¿Está seguro de ${
          status == 2 ? 'Inactivar' : status == 17 ? 'Eliminar' : 'Activar'
        } al usuario <span class="text-danger">${item.usuario}</span>?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise((resolve, reject) => {
            try {
              axiosInstance
                .put(`/users/changeStatus/`, { id: item.id, status: status })
                .then(() => {
                  item.idEstado = status;

                  resolve(true);
                })
                .catch((err) => {
                  if (err.response.status === 401) return;

                  Swal.fire({
                    title: 'Inconveniente en el sistema, intente más tarde',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                  });
                });
            } catch (e) {
              reject(false);
            }
          });
        },
      });
    };

    const changeStatusOtp = (item, status) => {
      Swal.fire({
        title: `¿Está seguro de ${
          status == 0 ? 'Inactivar' : 'Activar'
        } el código OTP al usuario <span class="text-danger">${
          item.usuario
        }</span>?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise((resolve, reject) => {
            try {
              axiosInstance
                .put(`/users/changeStatusOtp/`, { id: item.id, status: status })
                .then(() => {
                  item.enviarOtp = status;
                  resolve(true);
                })
                .catch((err) => {
                  if (err.response.status === 401) return;

                  Swal.fire({
                    title: 'Inconveniente en el sistema, intente más tarde',
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                  });
                });
            } catch (e) {
              reject(false);
            }
          });
        },
      });
    };

    // Informacion basica
    const modalUpdateInfo = (info) => {
      openModal.value = true;
      Object.assign(userCurrent, info);
    };

    const closeModal = () => {
      openModal.value = false;
      Object.assign(userCurrent, {});
    };

    // Credenciales
    const modalUpdateCredential = (info) => {
      openModalUser.value = true;
      Object.assign(userCurrent, info);
    };

    const closeModalCredential = () => {
      openModalUser.value = false;
      Object.assign(userCurrent, {});
    };

    // Sucursales
    const modalUpdateSucur = (info) => {
      openModalSucursal.value = true;
      Object.assign(userCurrent, info);
    };

    const closeModalSucursal = () => {
      openModalSucursal.value = false;
      Object.assign(userCurrent, {});
    };

    // Agregar Usuario
    const closeModalAdd = () => {
      openModalAddUser.value = false;
    };

    const download = async () => {
      downloadLoading.value = true;

      axiosInstance
        .get(
          `/exportUsers/dni/${currentFilter.dniSearch || -1}/fullname/${
            currentFilter.nameSearch || -1
          }/rol/${currentFilter.rolSearch}/state/${
            currentFilter.stateSearch
          }/rolUser/${getUser.idRol}/user/${getUser.userId}/otp/${
            currentFilter.otpSearch
          }`,
          { responseType: 'blob' }
        )
        .then(({ data }) => {
          downloadBlob(
            data,
            'Lista_de_Usuarios.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=ANSI'
          );
        })
        .finally(() => (downloadLoading.value = false));
    };

    const statusName = (id) => {
      return id == 1 ? 'Activo' : id == 17 ? 'Eliminado' : 'Inactivo';
    };

    const downloadCSV = () => {
      downloadCSVLoading.value = true;

      axiosInstance
        .get(
          `/getUsers/dni/-1/fullname/-1/rol/-1/state/1/rolUser/${getUser.idRol}/user/${getUser.userId}/otp/-1`
        )
        .then(({ data }) => {
          const listCsv = data.body.map((user) => {
            const {
              nombreUsuario,
              usuario,
              rolNombre,
              rolId,
              documento,
              idEstado,
            } = user;

            return {
              nameApp: 'Alala',
              nombreUsuario: removeDiacritics(nombreUsuario),
              usuario,
              rolNombre,
              descriptionRol: descriptionRoles[rolId],
              documento,
              statusName: statusName(idEstado),
            };
          });

          listCsv.unshift({
            nameApp: 'Nombre Aplicacion',
            nombreUsuario: 'Nombre Usuario',
            usuario: 'Usuario en la aplicacion',
            rolNombre: 'Perfil',
            descriptionRol: 'Descripcion perfil',
            documento: 'Documento',
            statusName: 'Estado de la cuenta',
          });

          const csvContent =
            'data:text/csv;charset=utf-8,' +
            listCsv.map((user) => Object.values(user).join(',')).join('\n');

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute(
            'download',
            `alala${getCurrentFormattedDate(true)}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 401) return;

          Swal.fire({
            title: 'Inconveniente en el sistema, intente más tarde',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => (downloadCSVLoading.value = false));
    };

    watch(
      () => bus.value.get('update-filter'),
      () => {
        page.value = 1;
      }
    );

    return {
      page,
      url,
      formatteNumber,
      displayedPosts,
      changeStatus,
      modalUpdateInfo,
      openModal,
      openModalUser,
      openModalSucursal,
      userCurrent,
      closeModal,
      modalUpdateCredential,
      closeModalCredential,
      modalUpdateSucur,
      closeModalSucursal,
      getUser,
      downloadLoading,
      download,
      formatDate,
      inactivarOrActivarMassivo,
      isCheck,
      openModalAddUser,
      closeModalAdd,
      changeStatusOtp,
      downloadCSVLoading,
      downloadCSV,
      statusName,
    };
  },
};
</script>

<style scoped>
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px !important;
}

.img-size {
  max-height: 248px !important;
  max-width: 328px !important;
}
</style>
