<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row justify-content-between">
        <div class="col-6">
          <h6>Listado de Solicitudes</h6>
        </div>

        <div class="col-6 col-md-3" style="text-align: right !important">
          <button type="button" class="btn btn-secondary mb-0" @click="download" :disabled="downloadLoading">
            <i v-if="downloadLoading" class="fas fa-circle-notch fa-spin"></i>
            <i v-else class="fas fa-download"></i> Descargar
          </button>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="container-fluid">
        <div v-if="!loading" class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Acciones
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      CC del Solicitante
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Nombre del Solicitante
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Convenio
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Municipio Convenio
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Nombre del Asesor
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      CC del Asesor
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Sucursal
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Tipo Cliente
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Fecha y Hora de Solicitud
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xs font-weight-bolder">
                      Fecha y Hora de Consulta
                    </th>
                  </tr>
                </thead>
                <tbody v-if="displayedPosts.length">
                  <tr v-for="item of displayedPosts" :key="item.idSolicitud">
                    <td class="text-center">
                      <Tooltip tooltipText="Detalles" position="right">
                        <button type="button" @click="openModal(item)"
                          class="btn btn-success btn-sm px-3 mb-0 text-light">
                          <span class="far fa-folder-open fa-lg"></span>
                        </button>
                      </Tooltip>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.documentoCliente }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.nombreCliente }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.nombreConvenio }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.localidad }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.nombreAsesor }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.documentoAsesor }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.surcursalNombre }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.tipoCliente }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ formatDate(
                        new Date(item.fechaCreacion)
                      ) }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ formatDate(
                        new Date(item.fechaConsulta)
                      ) }}</h6>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">
                      <h6 class="mb-0 text-sm">Sin resultados.</h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 d-flex">
            <v-pagination class="ms-md-auto my-3" v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
              @update:modelValue="page" />
          </div>

          <div class="modal" tabindex="-1" id="myModal">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Información de la Solicitud</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="background-color: black"></button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Nombre del Solicitante</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.nombreCliente" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Tipo de Identificación del Solicitante</label>
                          <input type="text" disabled class="form-control"
                            :value="solicitudCurrent.tipoDocumentoCliente" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Número de Identificación del Solicitante</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.documentoCliente" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Convenio</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.nombreConvenio" />
                        </div>
                      </div>
                      
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Municipio Convenio</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.localidad" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Nombre del Asesor</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.nombreAsesor" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Tipo de Identificación del Asesor</label>
                          <input type="text" disabled class="form-control"
                            :value="solicitudCurrent.tipoDocumentoAsesor" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Número de Identificación del Asesor</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.documentoAsesor" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">IP de Registro de Solicitud</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.IP" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Fecha de Registro de Solicitud</label>
                          <input type="text" disabled class="form-control" :value="formatDate(
                            new Date(solicitudCurrent.fechaCreacion)
                          )
                            " />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Sucursal</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.surcursalNombre" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Tipo Cliente</label>
                          <input type="text" disabled class="form-control" :value="solicitudCurrent.tipoCliente" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="mb-3">
                          <label class="form-label">Fecha y Hora de Consulta</label>
                          <input type="text" disabled class="form-control" :value="formatDate(
                            new Date(solicitudCurrent.fechaConsulta)
                          )
                            " />
                        </div>
                      </div>

                      <div v-show="showImg && !loadingImage" class="row">
                        <div v-if="showImg" class="col-12 col-sm-6 col-md-4">
                          <!-- Imagen Nómina Start -->
                          <div class="row">
                            <div class="col-12">
                              <h5>
                                {{ solicitudCurrent.jsonSoportes[0].type }}
                              </h5>
                            </div>
                            <div class="col-12 text-center">
                              <img :id="solicitudCurrent.jsonSoportes[0].hash" class="img-fluid img-thumbnail"
                                :alt="solicitudCurrent.jsonSoportes[0].nombre" />
                            </div>
                            <div class="col-12 text-center pt-3">
                              <button type="button" style="margin-right: 10px;" @click="
                                donwloadArchivo(
                                  solicitudCurrent.jsonSoportes[0]
                                )
                                " class="btn btn-sm btn-success">
                                Descargar
                              </button>

                              <button type="button" @click="
                                convertImgToPdf(
                                  solicitudCurrent.jsonSoportes[0]
                                )
                                " class="btn btn-sm btn-dark">
                                Descargar PDF
                              </button>
                            </div>
                          </div>
                          <!-- Imagen Nómina End -->
                        </div>

                        <div v-if="showImg" class="col-12 col-sm-6 col-md-4">
                          <div class="row">
                            <div class="col-12">
                              <!-- Imagen Foto CC Frontal Start -->
                              <div class="row">
                                <div class="col-12">
                                  <h5>
                                    {{ solicitudCurrent.jsonSoportes[1].type }}
                                  </h5>
                                </div>
                                <div class="col-12 text-center">
                                  <img :id="solicitudCurrent.jsonSoportes[1].hash"
                                    class="img-fluid img-thumbnail img-size" :alt="solicitudCurrent.jsonSoportes[1].nombre
                                      " />
                                </div>
                                <div class="col-12 text-center pt-3">
                                  <button type="button" @click="
                                    donwloadArchivo(
                                      solicitudCurrent.jsonSoportes[1]
                                    )
                                    " class="btn btn-sm btn-success">
                                    Descargar
                                  </button>
                                </div>
                              </div>
                              <!-- Imagen Foto CC Frontal End -->
                            </div>

                            <div class="col-12 mt-5">
                              <!-- Imagen Foto CC Posterior Start -->
                              <div class="row">
                                <div class="col-12">
                                  <h5>
                                    {{ solicitudCurrent.jsonSoportes[2].type }}
                                  </h5>
                                </div>
                                <div class="col-12 text-center">
                                  <img :id="solicitudCurrent.jsonSoportes[2].hash"
                                    class="img-fluid img-thumbnail img-size" :alt="solicitudCurrent.jsonSoportes[2].nombre
                                      " />
                                </div>
                                <div class="col-12 text-center pt-3">
                                  <button type="button" @click="
                                    donwloadArchivo(
                                      solicitudCurrent.jsonSoportes[2]
                                    )
                                    " class="btn btn-sm btn-success">
                                    Descargar
                                  </button>
                                </div>
                              </div>
                              <!-- Imagen Foto CC Posterior End -->
                            </div>
                          </div>
                        </div>

                        <div v-if="showImg" class="col-12 col-sm-6 col-md-4">
                          <div class="row">
                            <div class="col-12">
                              <!-- Imagen Foto Start -->
                              <div class="row">
                                <div class="col-12">
                                  <h5>
                                    {{ solicitudCurrent.jsonSoportes[3].type }}
                                  </h5>
                                </div>
                                <div class="col-12 text-center">
                                  <img :id="solicitudCurrent.jsonSoportes[3].hash"
                                    class="img-fluid img-thumbnail img-size" :alt="solicitudCurrent.jsonSoportes[3].nombre
                                      " />
                                </div>
                                <div class="col-12 text-center pt-3">
                                  <button type="button" @click="
                                    donwloadArchivo(
                                      solicitudCurrent.jsonSoportes[3]
                                    )
                                    " class="btn btn-sm btn-success">
                                    Descargar
                                  </button>
                                </div>
                              </div>
                              <!-- Imagen Foto End -->
                            </div>

                            <div class="col-12 mt-5">
                              <!-- Imagen Firma Start -->
                              <div class="row">
                                <div class="col-12">
                                  <h5>
                                    {{ solicitudCurrent.jsonSoportes[4].type }}
                                  </h5>
                                </div>
                                <div class="col-12 text-center">
                                  <img :id="solicitudCurrent.jsonSoportes[4].hash"
                                    class="img-fluid img-thumbnail img-size" :alt="solicitudCurrent.jsonSoportes[4].nombre
                                      " />
                                </div>
                                <div class="col-12 text-center pt-3">
                                  <button type="button" @click="
                                    donwloadArchivo(
                                      solicitudCurrent.jsonSoportes[4]
                                    )
                                    " class="btn btn-sm btn-success">
                                    Descargar
                                  </button>
                                </div>
                              </div>
                              <!-- Imagen Firma End -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-show="loadingImage" class="row">
                        <div class="col-12 text-center">
                          <span class="fas fa-circle-notch fa-spin fa-3x mb-4"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-dark" data-bs-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span v-if="loading" class="fas fa-circle-notch fa-spin fa-2x mb-4"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, computed } from "vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Tooltip from "@/components/Tooltip.vue";
import { API_BASEURL } from "@/helpers/config";
import axiosInstance from "@/services/api";
import { Modal } from "bootstrap";
import { formatDate, formatteNumber, downloadBlob } from "@/utils/functions";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import jsPDF from 'jspdf';

export default {
  name: "projects-card",

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    perPages: {
      type: Number,
      default: 10,
    },
    filter: {
      type: Object,
      default: () => ({}),
    }
  },

  components: {
    VPagination,
    Tooltip,
  },

  setup(props) {
    const store = useStore();

    const { getUser } = store.getters

    const { perPages, data, filter } = toRefs(props);
    const page = ref(1);
    const showImg = ref(false);
    const url = ref(API_BASEURL);
    const solicitudCurrent = reactive({});
    const cantImg = ref(0);
    const cantImgLoad = ref(0);
    const downloadLoading = ref(false)
    const currentFilter = reactive(filter.value)

    const paginate = (data) => {
      let _page = page.value;
      let _perPage = perPages.value;
      let _from = _page * _perPage - _perPage;
      let _to = _page * _perPage;
      return data.slice(_from, _to);
    };

    const displayedPosts = computed(() => {
      return paginate(data.value);
    });

    const loadingImage = computed(() => {
      if (cantImg.value == 0 && cantImgLoad.value == 0) return true

      return !(cantImg.value == cantImgLoad.value)
    });

    const openModal = async (item) => {
      Object.assign(solicitudCurrent, item);
      showImg.value = true;
      const myModal = new Modal(document.getElementById("myModal"), {});
      cantImg.value = item.jsonSoportes.length;
      cantImgLoad.value = 0;
      item.jsonSoportes.forEach((soporte) => {
        getImage(soporte.hash);
      });
      myModal.show();

      const payload = {
        userId: getUser.userId,
        action: item.documentoCliente
      }

      axiosInstance.post(`/save/info-logs`, payload)
    }

    const getImage = (hash) => {
      axiosInstance
        .get(`/getImage/${hash}`, { responseType: "blob" })
        .then(({ data }) => {
          cantImgLoad.value = cantImgLoad.value + 1;
          let link = window.URL.createObjectURL(data);
          let img = document.getElementById(hash);
          img.setAttribute("src", link);
        });
    };

    const donwloadArchivo = ({ hash, name }) => {
      axiosInstance
        .get(`/getImage/${hash}`, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          if (err.response.status === 401) return
          
          Swal.fire({
            title:
              "Inconvenientes al descargar archivo, por favor intentelo más tarde.",
            icon: "warning",
            confirmButtonText: "Aceptar",
          });
        });
    };

    const blobToBase64 = async (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const convertImgToPdf = async ({ hash, name }) => {
      await axiosInstance
        .get(`/getImage/${hash}`, {
          responseType: "blob",
        })
        .then(async ({ data }) => {
          // Version 2.0
          const pdfDoc = new jsPDF();

          const imageBase64 = await blobToBase64(data);

          pdfDoc.addImage(imageBase64, 'PNG', 50, 10, 90, 150); // Ajusta las coordenadas y el tamaño según tus necesidades

          const pdfBlob = pdfDoc.output('blob');

          const pdfUrl = URL.createObjectURL(pdfBlob);

          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = `${name}.pdf`;

          link.click();

          URL.revokeObjectURL(pdfUrl);
        })
        .catch(() => {
          Swal.fire({
            title:
              "Inconvenientes al descargar archivo, por favor intentelo más tarde.",
            icon: "warning",
            confirmButtonText: "Aceptar",
          });
        });
    };

    const download = async () => {
      downloadLoading.value = true

      axiosInstance
        .get(`/exportListRequests/dateIni/${currentFilter.dateStart || "-1"}/dateEnd/${currentFilter.dateEnd || "-1"}/dni/${currentFilter.numberSearch || "-1"}/user/${getUser.userId}/rol/${getUser.idRol}`, { responseType: 'blob' })
        .then(({ data }) => {
          downloadBlob(data, 'Lista_de_Solicitudes.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=ANSI')
        })
        .finally(() => downloadLoading.value = false)
    }

    return {
      page,
      url,
      openModal,
      solicitudCurrent,
      formatDate,
      formatteNumber,
      getImage,
      donwloadArchivo,
      displayedPosts,
      showImg,
      loadingImage,
      cantImg,
      cantImgLoad,
      downloadLoading,
      download,
      convertImgToPdf,
    };
  },
};
</script>

<style scoped>
.table-bordered> :not(caption)>*>* {
  border-width: 0 1px !important;
}

.img-size {
  max-height: 248px !important;
  max-width: 328px !important;
}
</style>
