import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/index";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import "./styles/main.scss";
import directives from "./directives/";
import router from "./router";
import setupInterceptors from "./services/setupInterceptors";
import ApexCharts from "apexcharts";
import VueApexCharts from "vue3-apexcharts";

setupInterceptors(store);

const appInstance = createApp(App);

directives(appInstance);

appInstance.config.globalProperties.$apexcharts = ApexCharts;

appInstance.use(VueApexCharts);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
