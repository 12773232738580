<template>
  <nav class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" v-bind="$attrs" id="navbarBlur"
    data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" id="navbar">
        <div class="pe-md-3 d-flex align-items-center ms-md-auto"></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center nav-link font-weight-bold text-body" style="margin-right: .5rem !important;">
            <i class="far fa-user-circle me-sm-1"></i>
            <span class="d-sm-inline d-none">{{ getUser.fullname }}</span>
          </li>
          
          <li class="nav-item d-flex align-items-center nav-link font-weight-bold text-body" style="margin-right: 2rem !important;">
            <i class="far fa-id-badge me-sm-1"></i>
            <span class="d-sm-inline d-none">Rol: {{ getUser.nameRol }}</span>
          </li>

          <li class="nav-item d-flex align-items-center">
            <a href="javascript:void(0)" @click="cerrarSesion" class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'">
              <i class="fas fa-sign-out-alt me-sm-1"></i>
              <span class="d-sm-inline d-none">Cerrar Sesión </span>
            </a>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="modal" tabindex="-1" id="myModalClose">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">¿Está seguro de <b>Cerrar Sesión</b>?</h5>
        </div>
        <div class="modal-body">
          <div class="content-fluid">
            <div class="row justify-content-center">
              <div class="col col-5 text-center">
                <button type="button" class="btn btn-success w-100" @click="closedConfirm()">
                  Salir
                </button>
              </div>
              <div class="col col-5 text-center">
                <button type="button" class="btn btn-danger w-100" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import TokenService from "@/services/token.service";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      modalClose: null,
      store: useStore(),
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.modalClose = new Modal(document.getElementById("myModalClose"), {})
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    cerrarSesion() {
      this.modalClose.show()
    },

    closedConfirm() {
      this.modalClose.hide();

      TokenService.removeUser();
      this.$router.push({ name: "login" });
    }
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    getUser() {
      const { getUser } = this.store.getters;
      return getUser;
    }
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (navbar) {
        if (window.scrollY > 10 && this.$store.state.isNavFixed) {
          navbar.classList.add("blur");
          navbar.classList.add("position-sticky");
          navbar.classList.add("shadow-blur");
        } else {
          navbar.classList.remove("blur");
          navbar.classList.remove("position-sticky");
          navbar.classList.remove("shadow-blur");
        }
      }
    });
  },
};
</script>
