<template>
  <div class="modal" tabindex="-1" id="myModalAddUserAssisted">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Agregar Usuario Asistido</b></h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="content-fluid">
              <div class="row justify-content-center">
                <div class="col-12 col-md-9">
                  <div class="form-group">
                    <label for="txtDni" class="form-control-label"
                      >Identificación del Cliente</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtDni"
                      v-model="form.documento"
                      @input="onlyNumberReg"
                      @keypress="onlyNumberReg"
                      @change="onlyNumberReg"
                    />
                    <div v-if="vau.documento.$error" class="invalid-feedback">
                      {{ vau.documento.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-9">
                  <div class="form-group">
                    <label for="txtConfirmDni" class="form-control-label"
                      >Confirmación de la Identificación</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtConfirmDni"
                      v-model="form.confirmaDocumento"
                      @input="onlyNumberReg"
                      @keypress="onlyNumberReg"
                      @change="onlyNumberReg"
                    />
                    <div
                      v-if="vau.confirmaDocumento.$error"
                      class="invalid-feedback"
                    >
                      {{ vau.confirmaDocumento.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-9">
                  <div class="form-group">
                    <label for="txtDate" class="form-control-label"
                      >Lugar de Expedición</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtDate"
                      v-model="form.placeIssuance"
                      @input="onlyAlPhaSpaceTilde"
                      @keypress="onlyAlPhaSpaceTilde"
                      @change="onlyAlPhaSpaceTilde"
                    />
                    <div
                      v-if="vau.placeIssuance.$error"
                      class="invalid-feedback"
                    >
                      {{ vau.placeIssuance.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            :disabled="loading"
            type="button"
            class="btn btn-danger"
            @click="closedModal"
          >
            Cancelar
          </button>
          <button
            :disabled="loading"
            type="button"
            class="btn btn-success"
            @click="addUser"
          >
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> Activar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MockAdapter from 'axios-mock-adapter';

import { ref, watchEffect, toRefs, onMounted, reactive, computed } from 'vue';
import { Modal } from 'bootstrap';
import { onlyNumberReg, onlyAlPhaSpaceTilde } from '@/utils/functions';
import { maxLength, required, helpers, sameAs } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import useEventsBus from '@/helpers/eventBus';

export default {
  name: 'modal-add-user',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { isOpen, user } = toRefs(props);

    const { emit: emitt } = useEventsBus();

    const modal = ref(null);
    const loading = ref(false);
    const form = reactive({
      documento: null,
      confirmaDocumento: null,
      placeIssuance: null,
    });

    const rules = computed(() => ({
      documento: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 20 caracteres', maxLength(20)),
      },
      confirmaDocumento: {
        required: helpers.withMessage('Campo requerido', required),
        sameAs: helpers.withMessage(
          'Las identificaciones deben coincidir',
          sameAs(form.documento)
        ),
      },
      placeIssuance: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 80 caracteres', maxLength(80)),
      },
    }));

    const vau = useVuelidate(rules, form);

    const closedModal = () => {
      vau.value.$reset();
      emit('close-modal', false);
    };

    const addUser = async () => {
      vau.value.$touch();

      if (vau.value.$invalid) return false;

      loading.value = true;

      const payload = {
        documento: form.confirmaDocumento,
        lugarExpedicion: form.placeIssuance,
        idAsesor: user.value.userId,
        idBanco: user.value.idEntidad,
      };

      await axiosInstance
        .post(`/user/assisted`, payload)
        .then(() => {
          Swal.fire({
            title: 'Usuario Activado',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#82d616',
          });

          vau.value.$reset();

          emitt('create-userAssisted-success', true);

          emit('close-modal', false);
        })
        .catch((err) => {
          if (err.response.status === 401) return;

          const { message } = err?.response?.data?.error || {};
          Swal.fire({
            text:
              message !== null &&
              message !== '' &&
              typeof message !== 'undefined'
                ? message
                : `Inconveniente en el sistema, intente más tarde.`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => (loading.value = false));
    };

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show();
        if (!isOpen.value) {
          vau.value.$reset();

          Object.assign(form, {
            documento: null,
            confirmaDocumento: null,
            placeIssuance: null,
          });

          modal.value.hide();
        }
      }
    });

    onMounted(() => {
      modal.value = new Modal(
        document.getElementById('myModalAddUserAssisted'),
        {}
      );
    });

    return {
      closedModal,
      form,
      onlyNumberReg,
      onlyAlPhaSpaceTilde,
      vau,
      addUser,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
