import AppInicio from '@/views/AppInicio.vue';
import AppGrafica from '@/views/AppGrafica.vue';
import AppUsuarios from '@/views/AppUsuarios.vue';
import AppLogSolicitud from '../views/AppLogSolicitud.vue';
import AppAsistidos from '@/views/AppAsistidos.vue';

export default [
  {
    path: '/inicio',
    name: 'Solicitudes',
    component: AppInicio,
  },
  {
    path: '/grafica',
    name: 'Estadísticas',
    component: AppGrafica,
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: AppUsuarios,
  },
  {
    path: '/logs',
    name: 'Logs',
    component: AppLogSolicitud,
  },
  {
    path: '/asistidos',
    name: 'Asistidos',
    component: AppAsistidos,
  },
];
