import AppLogin from "@/views/AppLogin.vue";

export default [
  {
    path: "/",
    name: "login",
    component: AppLogin,
  },
  {
    path: "/restablecer",
    name: "reset-password",
    component: () => import("@/views/AppRestablecer.vue"),
  },
  {
    path: "/pageRestablecer/:token",
    name: "page-reset",
    component: () => import("@/views/AppPageRestablecer.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: { name: "login" },
  },
];
