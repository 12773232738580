<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row justify-content-between">
        <div class="col-6">
          <h6>Logs de Consulta</h6>
        </div>

        <div class="col-6 col-md-3" style="text-align: right !important">
          <button
            type="button"
            class="btn btn-secondary mb-0"
            @click="download"
            :disabled="downloadLoading"
          >
            <i v-if="downloadLoading" class="fas fa-circle-notch fa-spin"></i>
            <i v-else class="fas fa-download"></i> Descargar
          </button>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="container-fluid">
        <div v-if="!loading" class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      No.
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      CC del Solicitante
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Nombre del Usuario de Alala
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Cant. de veces Consultadas
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Año
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Mes
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xs font-weight-bolder"
                    >
                      Última Fecha de Consulta
                    </th>
                  </tr>
                </thead>
                <tbody v-if="displayedPosts.length">
                  <tr v-for="(item, i) of displayedPosts" :key="item._id">
                    <td class="text-center">
                      {{ i + 1 }}
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ formatteNumber.format(item.customerID) }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ item.fullname }}</h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.quantity }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.year }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ item.monthName }}
                      </h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">
                        {{ formatDate(new Date(item.createdAt)) }}
                      </h6>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm"><b>Total</b></h6>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <h6 class="mb-0 text-sm">{{ totalQuantity }}</h6>
                    </td>
                    <td
                      colspan="3"
                      class="align-middle text-right text-sm"
                    ></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">
                      <h6 class="mb-0 text-sm">Sin resultados.</h6>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 d-flex">
            <v-pagination
              class="ms-md-auto my-3"
              v-model="page"
              :pages="pages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="page"
            />
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <span
              v-if="loading"
              class="fas fa-circle-notch fa-spin fa-2x mb-4"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, computed } from 'vue';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import axiosInstance from '@/services/api';
import { formatDate, formatteNumber, downloadBlob } from '@/utils/functions';

export default {
  name: 'projects-card',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    totalQuantity: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Number,
      default: 1,
    },
    perPages: {
      type: Number,
      default: 10,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    VPagination,
  },

  setup(props) {
    const { perPages, data, filter } = toRefs(props);
    const page = ref(1);
    const downloadLoading = ref(false);
    const currentFilter = reactive(filter.value);

    const paginate = (data) => {
      let _page = page.value;
      let _perPage = perPages.value;
      let _from = _page * _perPage - _perPage;
      let _to = _page * _perPage;
      return data.slice(_from, _to);
    };

    const displayedPosts = computed(() => {
      return paginate(data.value);
    });

    const download = async () => {
      downloadLoading.value = true;

      axiosInstance
        .get(
          `/click-counter/export/bank/${currentFilter.bankId}/dateIni/${currentFilter.dateStart}/dateEnd/${currentFilter.dateEnd}/export/1`,
          { responseType: 'blob' }
        )
        .then(({ data }) => {
          downloadBlob(
            data,
            'Lista_de_Logs.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=ANSI'
          );
        })
        .finally(() => (downloadLoading.value = false));
    };

    return {
      page,
      formatDate,
      formatteNumber,
      displayedPosts,
      downloadLoading,
      download,
    };
  },
};
</script>

<style scoped>
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px !important;
}

.img-size {
  max-height: 248px !important;
  max-width: 328px !important;
}
</style>
