export const downloadBlob = (blob, filename, typeName) => {
  const urlObject = window.URL.createObjectURL(
    new Blob([blob], { type: typeName })
  );
  const link = document.createElement('a');

  link.href = urlObject;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // remove element and urlobject
  link.remove();
  window.URL.revokeObjectURL(urlObject);
};

export const onlyNumberReg = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^[0-9]+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const onlyAlPhaNumericSimple = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^([A-Z]|[a-z]|[0-9]|[.\-_])+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const onlyAlPhaNumeric = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^([A-Z]|[a-z]|[0-9]|[']|[ñÑ])+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const onlyAlPhaNumericSpace = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^([A-Z]|[a-z]|[0-9]|[']|[ñÑ]|\s)+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const onlyAlPhaNumericSpaceTilde = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^([A-Z]|[a-z]|[áéíóúÁÉÍÓÚüÜ]|[0-9]|[']|[ñÑ]|\s)+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const onlyAlPhaSpaceTilde = (event) => {
  if (event.target.value < 0) {
    event.preventDefault();
    event.target.value = '';
  } else {
    if (!/^([A-Z]|[a-z]|[áéíóúÁÉÍÓÚüÜ]|[ñÑ]|\s)+$/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const notCopyPaste = (event) => {
  event.preventDefault();
};

// Format new Date to (yyyy-mm-dd hh:mm:ss)
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
};

export const formatteNumber = new Intl.NumberFormat('es-CO', {
  currency: 'COP',
  minimumFractionDigits: 0,
});

export const concat_ws = (separator, ...args) => {
  return args
    .filter((arg) => arg !== null && arg !== undefined)
    .join(separator);
};

export const getCurrentFormattedDate = (onlyDate) => {
  const date = new Date();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  if (onlyDate) return `${year}${month}${day}`;

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const removeDiacritics = (str) => {
  const diacriticsMap = {
    á: 'a',
    Á: 'A',
    é: 'e',
    É: 'E',
    í: 'i',
    Í: 'I',
    ó: 'o',
    Ó: 'O',
    ú: 'u',
    Ú: 'U',
    ñ: 'n',
    Ñ: 'N',
  };

  return str.replace(/[áÁéÉíÍóÓúÚñÑ]/g, function (match) {
    return diacriticsMap[match];
  });
};
