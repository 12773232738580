<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Filters Start -->
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-3">
                <label for="dniSearch" class="form-label"
                  >No de Identificación</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="dniSearch"
                  v-model="dniSearch"
                  @input="onlyNumberReg"
                  @change="onlyNumberReg"
                />
                <div v-if="vf.dniSearch.$error" class="invalid-feedback">
                  {{ vf.dniSearch.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label for="nameSearch" class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameSearch"
                  v-model="nameSearch"
                  @keypress="onlyAlPhaNumericSpace"
                  @change="onlyAlPhaNumericSpace"
                  @copy="onlyAlPhaNumericSpace"
                  @paste="onlyAlPhaNumericSpace"
                />
                <div v-if="vf.nameSearch.$error" class="invalid-feedback">
                  {{ vf.nameSearch.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="rolSearch">Perfiles</label>
                  <select
                    class="form-control"
                    id="rolSearch"
                    v-model="rolSearch"
                  >
                    <option
                      v-for="item of listRoles"
                      :key="item.key"
                      :value="item.key"
                    >
                      {{ item.value }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="stateSearch">Estados</label>
                  <select
                    class="form-control"
                    id="stateSearch"
                    v-model="stateSearch"
                  >
                    <option value="-1">Todos</option>
                    <option value="1">Activo</option>
                    <option value="2">Inactivo</option>
                    <option value="17">Eliminado</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label for="otpSearch">OTP</label>
                  <select
                    class="form-control"
                    id="otpSearch"
                    v-model="otpSearch"
                  >
                    <option value="-1">Todos</option>
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-2">
                <button
                  type="button"
                  class="btn btn-secondary mb-0 w-100"
                  style="margin-top: 30px"
                  @click="clear"
                  :disabled="loading"
                >
                  Limpiar
                </button>
              </div>

              <div class="col-12 col-sm-6 col-md-2">
                <button
                  type="button"
                  class="btn btn-success mb-0 w-100"
                  style="margin-top: 30px"
                  @click="search"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  <i v-else class="fas fa-filter"></i> Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters End -->
    </div>
    <div class="row">
      <div class="col-12">
        <table-users
          :user-list="listUsers"
          :loading="loading"
          :pages="pages"
          :perPages="perPages"
          :filter="filter"
          @updateList="updateList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableUsers from "./components/TableUsers.vue";
import { onlyNumberReg, onlyAlPhaNumericSpace } from "@/utils/functions";
import { computed, ref, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import axiosInstance from "@/services/api";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { maxLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useEventsBus from "@/helpers/eventBus";

export default {
  components: {
    TableUsers,
  },

  setup() {
    const store = useStore();

    const { bus, emit } = useEventsBus();

    const { getUser } = store.getters;

    const dniSearch = ref(null);
    const nameSearch = ref(null);
    const rolSearch = ref(-1);
    const stateSearch = ref(-1);
    const otpSearch = ref(-1);

    const loading = ref(true);
    const listUsers = ref([]);
    const listRoles = ref({ key: -1, value: "Todos" });
    const router = useRouter();
    const pages = ref(1);
    const perPages = ref(10);

    const filter = reactive({
      dniSearch: null,
      nameSearch: null,
      rolSearch: -1,
      stateSearch: -1,
      otpSearch: -1,
    });

    const rules = computed(() => ({
      dniSearch: {
        maxLength: helpers.withMessage("Máximo 50 caracteres", maxLength(50)),
      },
      nameSearch: {
        maxLength: helpers.withMessage("Máximo 200 caracteres", maxLength(200)),
      },
    }));

    const vf = useVuelidate(rules, { dniSearch, nameSearch });

    const getUserList = (
      dniSearch,
      nameSearch,
      rolSearch,
      stateSearch,
      otpSearch,
      isFilter
    ) => {
      const statusIncludes = getUser.idRol == 1 ? [1, 2, 17] : [1, 2];

      axiosInstance
        .get(
          `/getUsers/dni/${dniSearch || -1}/fullname/${
            nameSearch || -1
          }/rol/${rolSearch}/state/${stateSearch}/rolUser/${
            getUser.idRol
          }/user/${getUser.userId}/otp/${otpSearch}`
        )
        .then(({ data }) => {
          listUsers.value = data.body
            .filter((i) => statusIncludes.includes(i.idEstado))
            .map((i) => ({ ...i, check: false }));
          pages.value = Math.ceil(listUsers.value.length / perPages.value);
          if (isFilter) {
            emit("update-filter", true);
          }
        })
        .catch((err) => {
          listUsers.value = [];

          if (err.response.status === 401) return;

          Swal.fire({
            title: "Inconveniente en el sistema, intente más tarde",
            icon: "warning",
            confirmButtonText: "Aceptar",
          });
        })
        .finally(() => (loading.value = false));
    };

    getUserList(-1, -1, -1, -1, -1, false);

    axiosInstance
      .get(`/getRoles/${getUser.idRol}`)
      .then(({ data }) => {
        listRoles.value = [
          { key: -1, value: "Todos" },
          ...data.body
            .filter(({ id }) => id != 5)
            .map(({ id, nombre }) => ({ key: id, value: nombre })),
        ];
      })
      .catch(() => {
        listRoles.value = [{ key: -1, value: "Todos" }];
      })
      .finally(() => (loading.value = false));

    const clear = async () => {
      dniSearch.value = null;
      nameSearch.value = null;
      rolSearch.value = -1;
      stateSearch.value = -1;
      otpSearch.value = -1;

      getUserList(-1, -1, -1, -1, -1, true);
    };

    const updateList = async () => {
      loading.value = true;

      filter.dniSearch = dniSearch.value;
      filter.nameSearch = nameSearch.value;
      filter.rolSearch = rolSearch.value;
      filter.stateSearch = stateSearch.value;
      filter.otpSearch = otpSearch.value;

      getUserList(
        dniSearch.value,
        nameSearch.value,
        rolSearch.value,
        stateSearch.value,
        otpSearch.value,
        false
      );
    };

    const search = async () => {
      vf.value.$touch();

      if (vf.value.dniSearch.$invalid || vf.value.nameSearch.$invalid)
        return false;

      loading.value = true;

      filter.dniSearch = dniSearch.value;
      filter.nameSearch = nameSearch.value;
      filter.rolSearch = rolSearch.value;
      filter.stateSearch = stateSearch.value;
      filter.otpSearch = otpSearch.value;

      getUserList(
        dniSearch.value,
        nameSearch.value,
        rolSearch.value,
        stateSearch.value,
        otpSearch.value,
        true
      );
    };

    watch(
      () => bus.value.get("updated-success"),
      (value) => {
        listUsers.value = listUsers.value.map((item) =>
          item.id === value[0].id ? { ...item, ...value[0] } : item
        );
      }
    );

    watch(
      () => bus.value.get("updated-user-success"),
      (value) => {
        listUsers.value = listUsers.value.map((item) =>
          item.id === value[0].id
            ? { ...item, usuario: value[0].usuario }
            : item
        );
      }
    );

    watch(
      () => bus.value.get("updated-sucursal-success"),
      (value) => {
        listUsers.value = listUsers.value.map((item) =>
          item.id === value[0].id ? { ...item, ...value[0] } : item
        );
      }
    );

    watch(
      () => bus.value.get("create-user-success"),
      () => {
        getUserList(
          dniSearch.value,
          nameSearch.value,
          rolSearch.value,
          stateSearch.value,
          otpSearch.value,
          true
        );
      }
    );

    return {
      loading,
      listUsers,
      router,
      pages,
      perPages,
      onlyNumberReg,
      onlyAlPhaNumericSpace,
      search,
      dniSearch,
      nameSearch,
      rolSearch,
      listRoles,
      stateSearch,
      otpSearch,
      getUser,
      vf,
      clear,
      filter,
      updateList,
    };
  },
};
</script>
