<template>
  <div class="p-3 pb-0 card-header">
    <h6>{{ title }}</h6>
    <p v-if="description" class="text-sm" v-html="description" />
  </div>
  <div class="p-3 card-body">
    <div v-if="Object.keys(chart).length && chart.labels.length > 0" class="chart">
      <apexchart v-for="(item, ind) of listChartOptions" :key="ind" :type="typeChar" :height="height"
        :options="item" :series="listChartSeries[ind]" class="my-2"></apexchart>
    </div>

    <div v-else>
      <div class="alert alert-secondary text-white text-center" role="alert">
        Sin registros
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    typeChar: {
      type: String,
      default: "bar"
    },
    plotOptions: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: Number,
      default: 300,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chart: {
      type: Object,
      default: () => ({})
    },
    titleY: {
      type: String,
      default: null,
    },
    titleX: {
      type: String,
      default: null,
    },
  },

  data: () => {
    return {
      colors: ["#a18cd1", "#ff9a9e", "#878d7f", "#a6c0fe", "#330867", "#f9748f"]
    }
  },

  computed: {
    listChartOptions() {
      const LENGTH_DEFAULT = 20
      const groupCount = Math.ceil(this.chart.labels.length / LENGTH_DEFAULT)
      let start = 0
      let end = LENGTH_DEFAULT

      let listOptions = []

      for (let i = 0; i < groupCount; i++) {
        listOptions.push({
          chart: {
            id: `${this.id}-${i}`,
          },
          plotOptions: this.plotOptions,
          xaxis: {
            title: {
              text: this.titleX,
              style: {
                color: "#878d7f",
                fontSize: 14,
                fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
              }
            },
            categories: this.chart.labels.slice(start, end),
          },
          yaxis: {
            title: {
              text: this.titleY,
              style: {
                color: "#878d7f",
                fontSize: 14,
                fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
              }
            },
          }
        })

        start = start + LENGTH_DEFAULT
        end = end + LENGTH_DEFAULT
      }

      return listOptions
    },
    chartOptions() {
      return {
        chart: {
          id: this.id,
        },
        plotOptions: this.plotOptions,
        xaxis: {
          title: {
            text: this.titleX,
            style: {
              color: "#878d7f",
              fontSize: 14,
              fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
            }
          },
          categories: this.chart.labels.slice(0, 20),
        },
        yaxis: {
          title: {
            text: this.titleY,
            style: {
              color: "#878d7f",
              fontSize: 14,
              fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
            }
          },
        }
      }
    },
    listChartSeries() {
      const LENGTH_DEFAULT = 20
      const groupCount = Math.ceil(this.chart.labels.length / LENGTH_DEFAULT)
      let start = 0
      let end = LENGTH_DEFAULT

      let listSeries = []

      for (let i = 0; i < groupCount; i++) {
        listSeries.push([
          {
            name: this.chart.datasets[0].label.slice(start, end),
            data: this.chart.datasets[0].data.slice(start, end),
          },
        ])

        start = start + LENGTH_DEFAULT
        end = end + LENGTH_DEFAULT
      }

      return listSeries
    },
    series() {
      return [
        {
          name: this.chart.datasets[0].label.slice(0, 20),
          data: this.chart.datasets[0].data.slice(0, 20),
        },
      ]
    }
  },
};
</script>
