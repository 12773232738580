<template>
  <div class="modal" tabindex="-1" id="myModalSucursal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Actualizar Sucursal</b></h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="content-fluid">
              <div class="row ">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="txtDni" class="form-control-label">No. de identificación</label>
                    <input class="form-control" type="text" id="txtDni" v-model="currentUser.documento" disabled>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="txtNombre" class="form-control-label">Nombre</label>
                    <input class="form-control" type="text" id="txtNombre" v-model="currentUser.nombreUsuario" disabled>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="txtBanco" class="form-control-label">Banco</label>
                    <input class="form-control" type="text" id="txtBanco" v-model="currentUser.nombreBanco" disabled>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="txtSucursal" class="form-control-label">Sucursal Actual</label>
                    <input class="form-control" type="text" id="txtSucursal" v-model="currentUser.nombreSucursal"
                      disabled>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="newSucursal">Nueva Sucursal</label>
                    <select class="form-control" id="newSucursal" v-model="selectSucursal">
                      <option v-for="item of listSucursal" :key="item.key" :value="item.key">{{ item.value }}</option>
                    </select>
                    <div v-if="vus.selectSucursal.$error" class="invalid-feedback">
                      {{ vus.selectSucursal.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button :disabled="loading" type="button" class="btn btn-danger" @click="closedModal">
            Cancelar
          </button>
          <button :disabled="loading" type="button" class="btn btn-success" @click="updated">
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MockAdapter from 'axios-mock-adapter';

import { ref, watchEffect, toRefs, onMounted, reactive, computed, watch } from 'vue';
import { Modal } from "bootstrap";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axiosInstance from "@/services/api";
import Swal from "sweetalert2";
import useEventsBus from '@/helpers/eventBus';
import { useStore } from "vuex";

export default {
  name: "modal-update-sucursal",

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const store = useStore();

    const { getUser } = store.getters

    const { isOpen, user } = toRefs(props)

    const { emit: emitt } = useEventsBus()

    const modal = ref(null)
    const loading = ref(false)
    const currentUser = reactive(user.value)

    const selectSucursal = ref(null)
    const listSucursal = ref([{ key: null, value: 'Seleccione una sucursal' }])
    const listAllSucur = ref([])

    // const mock = new MockAdapter(axiosInstance);

    // mock.onPut().reply(200, { body: 'Ok' });

    axiosInstance
      .get(`/getSucursal/user/${getUser.userId}/rol/${getUser.idRol}`)
      .then(({ data }) => {
        listAllSucur.value = data?.body || []
      })
      .catch(() => {
        listAllSucur.value = []
      });

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show()
        if (!isOpen.value) modal.value.hide()
      }
    })

    const rules = computed(() => ({
      selectSucursal: {
        required: helpers.withMessage("Seleccione una sucursal", required),
      },
    }));

    const vus = useVuelidate(rules, { selectSucursal });

    const closedModal = () => {
      vus.value.$reset()
      emit("close-modal", false)
      selectSucursal.value = null
    }

    const updated = async () => {
      vus.value.$touch();

      if (vus.value.$invalid) return false;

      loading.value = true

      const payload = {
        idPersona: currentUser.idPersona,
        idSucursal: selectSucursal.value,
        idRolUser: getUser.idRol
      }

      await axiosInstance
        .put(`/users/update/sucursal`, payload)
        .then(() => {
          Swal.fire({
            title: "Sucursal Actualizada",
            icon: "success",
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#82d616',
          })

          vus.value.$reset()

          currentUser.idSucursal = selectSucursal.value
          currentUser.nombreSucursal = listSucursal.value.find(item => item.key == selectSucursal.value)?.value

          emitt('updated-sucursal-success', currentUser)
          emit("close-modal", false)

          selectSucursal.value = null
          listSucursal.value = [{ key: null, value: 'Seleccione una sucursal' }]
        })
        .catch((err) => {
          if (err.response.status === 401) return
          
          const { message } = err?.response?.data?.error || {};
          Swal.fire({
            text:
              message !== null &&
                message !== "" &&
                typeof message !== "undefined"
                ? message
                : `Inconveniente en el sistema, intente más tarde.`,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        })
        .finally(() => (loading.value = false));
    }

    watch(props.user, value => {
      const list = listAllSucur.value
        .filter(s => s.idBanco == value.idBanco)
        .map(item => ({ key: item.id, value: item.nombre }))

      listSucursal.value = [{ key: null, value: 'Seleccione una sucursal' }, ...list]
    })

    onMounted(() => {
      modal.value = new Modal(document.getElementById("myModalSucursal"), {})
    })

    return {
      closedModal,
      currentUser,
      vus,
      updated,
      loading,
      selectSucursal,
      listSucursal,
      listAllSucur,
    }
  }
}
</script>

<style lang="scss" scoped></style>