import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import TokenService from "@/services/token.service";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _, next) => {
  const { name } = to;

  const list = ["login", "reset-password", "page-reset"];

  if (name != null && !list.includes(name)) {
    if (TokenService.getLocalAccessToken()) {
      if (TokenService.getUser()?.menus.length > 0) {
        const menus = TokenService.getUser().menus;

        const listMenu = menus.map((menu) => {
          return menu.nameRouter;
        });

        if (listMenu.includes(name)) {
          next();
        } else {
          const menuDefault = menus.filter((menu) => menu.default == 1);

          next({ name: menuDefault[0].nameRouter });
        }
      } else {
        TokenService.removeUser();
        next({ name: "login" });
      }
    } else {
      TokenService.removeUser();
      next({ name: "login" });
    }
  }

  next();
});

export default router;
