<template>
  <div class="modal" tabindex="-1" id="myModalAddUser">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Agregar Usuario</b></h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="content-fluid">
              <div class="row">
                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtNombre" class="form-control-label"
                      >Nombre</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtNombre"
                      v-model="form.nombre"
                      @input="onlyAlPhaNumericSpaceTilde"
                      @keypress="onlyAlPhaNumericSpaceTilde"
                      @change="onlyAlPhaNumericSpaceTilde"
                      @copy="onlyAlPhaNumericSpaceTilde"
                      @paste="onlyAlPhaNumericSpaceTilde"
                    />
                    <div v-if="vau.nombre.$error" class="invalid-feedback">
                      {{ vau.nombre.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtApellido" class="form-control-label"
                      >Apellido</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtApellido"
                      v-model="form.apellido"
                      @input="onlyAlPhaNumericSpaceTilde"
                      @keypress="onlyAlPhaNumericSpaceTilde"
                      @change="onlyAlPhaNumericSpaceTilde"
                      @copy="onlyAlPhaNumericSpaceTilde"
                      @paste="onlyAlPhaNumericSpaceTilde"
                    />
                    <div v-if="vau.apellido.$error" class="invalid-feedback">
                      {{ vau.apellido.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtDni" class="form-control-label"
                      >No. de Identificación</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtDni"
                      v-model="form.documento"
                      @input="onlyNumberReg"
                      @keypress="onlyNumberReg"
                      @change="onlyNumberReg"
                    />
                    <div v-if="vau.documento.$error" class="invalid-feedback">
                      {{ vau.documento.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtCelular" class="form-control-label"
                      >Teléfono</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtCelular"
                      v-model="form.celular"
                      @input="onlyNumberReg"
                      @keypress="onlyNumberReg"
                      @change="onlyNumberReg"
                    />
                    <div v-if="vau.celular.$error" class="invalid-feedback">
                      {{ vau.celular.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtCorreo" class="form-control-label"
                      >Correo</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtCorreo"
                      v-model="form.email"
                    />
                    <div v-if="vau.email.$error" class="invalid-feedback">
                      {{ vau.email.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtUsuario" class="form-control-label"
                      >Usuario</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="txtUsuario"
                      v-model="form.usuario"
                      @input="onlyAlPhaNumeric"
                      @keypress="onlyAlPhaNumeric"
                      @change="onlyAlPhaNumeric"
                    />
                    <div v-if="vau.usuario.$error" class="invalid-feedback">
                      {{ vau.usuario.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtPassword" class="form-control-label"
                      >Contraseña</label
                    >
                    <input
                      class="form-control"
                      type="password"
                      id="txtPassword"
                      v-model="form.password"
                    />
                    <div v-if="vau.password.$error" class="invalid-feedback">
                      {{ vau.password.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="txtConfirmPassword" class="form-control-label"
                      >Confirmar Contraseña</label
                    >
                    <input
                      class="form-control"
                      type="password"
                      id="txtConfirmPassword"
                      v-model="form.confirm_password"
                    />
                    <div
                      v-if="vau.confirm_password.$error"
                      class="invalid-feedback"
                    >
                      {{ vau.confirm_password.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <label for="rolSearch">Perfil</label>
                    <select
                      class="form-control"
                      id="rolSearch"
                      v-model="form.rolId"
                    >
                      <option
                        v-for="item of listRoles"
                        :key="item.key"
                        :value="item.key"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <div v-if="vau.rolId.$error" class="invalid-feedback">
                      {{ vau.rolId.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="txtBanco">Banco</label>
                    <select
                      class="form-control"
                      id="txtBanco"
                      :value="form.banco"
                      @change="setBanco"
                    >
                      <option
                        v-for="item of listBanco"
                        :key="item.key"
                        :value="item.key"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <div v-if="vau.banco.$error" class="invalid-feedback">
                      {{ vau.banco.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-5" v-if="form.rolId == 4">
                  <div class="form-group">
                    <label for="txtSucursal">Sucursal</label>
                    <select
                      class="form-control"
                      id="txtSucursal"
                      v-model="form.sucursal"
                    >
                      <option
                        v-for="item of listSucursal"
                        :key="item.key"
                        :value="item.key"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <div v-if="vau.sucursal.$error" class="invalid-feedback">
                      {{ vau.sucursal.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            :disabled="loading"
            type="button"
            class="btn btn-danger"
            @click="closedModal"
          >
            Cancelar
          </button>
          <button
            :disabled="loading"
            type="button"
            class="btn btn-success"
            @click="addUser"
          >
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MockAdapter from 'axios-mock-adapter';

import {
  ref,
  watchEffect,
  toRefs,
  onMounted,
  reactive,
  computed,
  watch,
} from 'vue';
import { Modal } from 'bootstrap';
import {
  onlyNumberReg,
  onlyAlPhaNumericSpace,
  onlyAlPhaNumericSpaceTilde,
} from '@/utils/functions';
import {
  maxLength,
  required,
  email,
  helpers,
  sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import useEventsBus from '@/helpers/eventBus';

export default {
  name: 'modal-add-user',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const { isOpen, user } = toRefs(props);

    const { emit: emitt } = useEventsBus();

    const modal = ref(null);
    const loading = ref(false);
    const form = reactive({
      nombre: null,
      apellido: null,
      documento: null,
      celular: null,
      email: null,
      usuario: null,
      password: null,
      confirm_password: null,
      rolId: null,
      banco: -1,
      sucursal: -1,
    });
    const listBanco = ref([{ key: -1, value: 'Seleccione un banco' }]);
    const listSucursal = ref([{ key: -1, value: 'Seleccione una sucursal' }]);
    const listRoles = [
      { key: null, value: 'Seleccione un perfil' },
      { key: 3, value: 'Administrador Banco' },
      { key: 7, value: 'Administrador Sucursal Nacional' },
      { key: 6, value: 'Administrador de Usuarios' },
      { key: 4, value: 'Administrador Sucursal' },
      { key: 9, value: 'Asesor Asistente' },
    ];

    // const mock = new MockAdapter(axiosInstance);

    // mock.onPost().reply(200, true);

    axiosInstance.get(`/banks`).then(({ data }) => {
      const { body = [] } = data;

      body.shift();

      listBanco.value = [
        { key: -1, value: 'Seleccione un banco' },
        ...body
          .filter(
            (i) => user.value.idEntidad == null || i.id == user.value.idEntidad
          )
          .map((i) => ({ key: i.id, value: i.name })),
      ];

      if (user.value.idRol != 1) form.banco = user.value.idEntidad;

      if (user.value.idEntidad) {
        axiosInstance
          .get(`/branch-offices-by-bank/${user.value.idEntidad}`)
          .then(({ data }) => {
            const { body = [] } = data;

            listSucursal.value = [
              { key: -1, value: 'Seleccione una sucursal' },
              ...body.map((i) => ({ key: i.idSucursal, value: i.nombre })),
            ];
          })
          .catch(() => {
            listSucursal.value = [
              { key: -1, value: 'Seleccione una sucursal' },
            ];
          })
          .finally(() => (form.sucursal = -1));
      }
    });

    const setBanco = (event) => {
      const value = event.target.value;

      form.banco = value;

      if (value == -1) {
        listSucursal.value = [{ key: -1, value: 'Seleccione una sucursal' }];

        form.sucursal = -1;
      } else {
        axiosInstance
          .get(`/branch-offices-by-bank/${value}`)
          .then(({ data }) => {
            const { body = [] } = data;

            listSucursal.value = [
              { key: -1, value: 'Seleccione una sucursal' },
              ...body.map((i) => ({ key: i.idSucursal, value: i.nombre })),
            ];
          })
          .catch(() => {
            listSucursal.value = [
              { key: -1, value: 'Seleccione una sucursal' },
            ];
          })
          .finally(() => (form.sucursal = -1));
      }
    };

    const rules = computed(() => ({
      nombre: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 100 caracteres', maxLength(100)),
      },
      apellido: {
        required: helpers.withMessage(
          'Campo requerido',
          (value) => form.rolId == 2 || (form.rolId != 2 && value.length > 0)
        ),
        maxLength: helpers.withMessage('Máximo 100 caracteres', maxLength(100)),
      },
      documento: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 20 caracteres', maxLength(20)),
      },
      celular: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 10 caracteres', maxLength(10)),
      },
      email: {
        required: helpers.withMessage('Campo requerido', required),
        email: helpers.withMessage(
          'Debe tener una estructura válida de correo electrónico',
          email
        ),
      },
      usuario: {
        required: helpers.withMessage('Campo requerido', required),
        maxLength: helpers.withMessage('Máximo 50 caracteres', maxLength(50)),
      },
      password: {
        required: helpers.withMessage('Campo requerido', required),
        securityPassword: helpers.withMessage(
          'La contraseña debe contener mínimo 8 dígitos, una mayúscula, una minúscula, un número.',
          (value) => {
            if (typeof value === 'undefined' || value === null || value === '')
              return true;
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d$@$!%*?&]|[^ ]){8,}$/.test(
              value
            );
          }
        ),
        maxLength: helpers.withMessage('Máximo 50 caracteres', maxLength(50)),
      },
      confirm_password: {
        required: helpers.withMessage('Campo requerido', required),
        sameAs: helpers.withMessage(
          'Las contraseñas deben coincidir',
          sameAs(form.password)
        ),
      },
      rolId: {
        required: helpers.withMessage('Campo requerido', required),
      },
      banco: {
        required: helpers.withMessage('Campo requerido', required),
      },
      sucursal: {
        required: helpers.withMessage(
          'Campo requerido',
          (value) => form.rolId != 4 || (form.rolId == 4 && value != -1)
        ),
      },
    }));

    const vau = useVuelidate(rules, form);

    const closedModal = () => {
      vau.value.$reset();
      emit('close-modal', false);
    };

    const addUser = async () => {
      vau.value.$touch();

      if (vau.value.$invalid) return false;

      loading.value = true;

      const payload = {
        idBanco: form.rolId == 4 ? form.sucursal : form.banco,
        nombre: form.nombre,
        apellido: form.apellido,
        documento: form.documento,
        celular: form.celular,
        email: form.email,
        usuario: form.usuario,
        clave: form.confirm_password,
        idRol: form.rolId,
      };

      await axiosInstance
        .post(`/user`, payload)
        .then(() => {
          Swal.fire({
            title: 'Usuario Creado',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#82d616',
          });

          vau.value.$reset();

          emitt('create-user-success', true);

          emit('close-modal', false);
        })
        .catch((err) => {
          if (err.response.status === 401) return;

          const { message } = err?.response?.data?.error || {};
          Swal.fire({
            text:
              message !== null &&
              message !== '' &&
              typeof message !== 'undefined'
                ? message
                : `Inconveniente en el sistema, intente más tarde.`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => (loading.value = false));
    };

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show();
        if (!isOpen.value) {
          vau.value.$reset();

          Object.assign(form, {
            nombre: null,
            apellido: null,
            documento: null,
            celular: null,
            email: null,
            usuario: null,
            password: null,
            confirm_password: null,
            rolId: null,
            sucursal: -1,
          });

          modal.value.hide();
        }
      }
    });

    watch(
      () => form.documento,
      () => {
        form.usuario = form.documento;
      }
    );

    onMounted(() => {
      modal.value = new Modal(document.getElementById('myModalAddUser'), {});
    });

    return {
      closedModal,
      form,
      onlyNumberReg,
      onlyAlPhaNumericSpace,
      onlyAlPhaNumericSpaceTilde,
      vau,
      addUser,
      loading,
      listRoles,
      listSucursal,
      listBanco,
      setBanco,
    };
  },
};
</script>

<style lang="scss" scoped></style>
