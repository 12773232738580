<template>
  <div class="modal" tabindex="-1" id="myModalCredentials">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Actualizar Credenciales</b></h5>
        </div>
        <div class="modal-body">
          <div class="content-fluid">
            <div class="row">
              <form>
                <div class="form-group">
                  <label for="txtUsuario" class="form-control-label">Usuario</label>
                  <input class="form-control" type="text" id="txtUsuario" v-model="currentUser.usuario"
                    @keypress="onlyAlPhaNumeric" @change="onlyAlPhaNumeric" @copy="onlyAlPhaNumeric"
                    @paste="onlyAlPhaNumeric">
                  <div v-if="vuc.usuario.$error" class="invalid-feedback">
                    {{ vuc.usuario.$errors[0].$message }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="txtPassword" class="form-control-label">Nueva Contraseña</label>
                  <input class="form-control" type="password" id="txtPassword" v-model="currentUser.password">
                  <div v-if="vuc.password.$error" class="invalid-feedback">
                    {{ vuc.password.$errors[0].$message }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="txtConfirmPassword" class="form-control-label">Confirmar Nueva Contraseña</label>
                  <input class="form-control" type="password" id="txtConfirmPassword"
                    v-model="currentUser.confirm_password">
                  <div v-if="vuc.confirm_password.$error" class="invalid-feedback">
                    {{ vuc.confirm_password.$errors[0].$message }}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button :disabled="loading" type="button" class="btn btn-danger" @click="closedModal">
            Cancelar
          </button>
          <button :disabled="loading" type="button" class="btn btn-success" @click="updated">
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MockAdapter from 'axios-mock-adapter';

import { ref, watchEffect, toRefs, onMounted, reactive, computed, watch } from 'vue';
import { Modal } from "bootstrap";
import { onlyNumberReg, onlyAlPhaNumeric } from "@/utils/functions";
import { maxLength, required, sameAs, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axiosInstance from "@/services/api";
import Swal from "sweetalert2";
import useEventsBus from '@/helpers/eventBus'

export default {
  name: "modal-update-credentials",

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const { isOpen } = toRefs(props)

    const { emit: emitt } = useEventsBus()

    const modal = ref(null)
    const loading = ref(false)
    const currentUser = reactive({
      id: null,
      usuario: null,
      password: null,
      confirm_password: null
    })

    // const mock = new MockAdapter(axiosInstance);

    // mock.onPost().reply(200, true);

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show()
        if (!isOpen.value) modal.value.hide()
      }
    })

    const rules = computed(() => ({
      usuario: {
        required: helpers.withMessage("Campo requerido", required),
        maxLength: helpers.withMessage("Máximo 50 caracteres", maxLength(50)),
      },
      password: {
        required: helpers.withMessage("Nueva contraseña es requerida", required),
        securityPassword: helpers.withMessage(
          "La contraseña debe contener mínimo 8 dígitos, una mayúscula, una minúscula, un número.",
          (value) => {
            if (typeof value === "undefined" || value === null || value === "")
              return true;
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d$@$!%*?&]|[^ ]){8,}$/.test(
              value
            );
          }
        ),
        maxLength: helpers.withMessage("Máximo 50 caracteres", maxLength(50)),
      },
      confirm_password: {
        required: helpers.withMessage(
          "Confirmación de nueva contraseña es requerida",
          required
        ),
        sameAs: helpers.withMessage(
          "Las contraseñas deben coincidir",
          sameAs(currentUser.password)
        ),
      },
    }));

    const vuc = useVuelidate(rules, currentUser);

    const closedModal = () => {
      vuc.value.$reset()
      emit("close-modal", false)
    }

    const updated = async () => {
      vuc.value.$touch();

      if (vuc.value.$invalid) return false;

      loading.value = true

      const payload = {
        id: currentUser.id,
        usuario: currentUser.usuario,
        pass: currentUser.confirm_password,
      }

      await axiosInstance
        .put(`/users/update/user`, payload)
        .then(() => {
          Swal.fire({
            title: "Credenciales Actualizadas",
            icon: "success",
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#82d616',
          })

          vuc.value.$reset()

          emitt('updated-user-success', currentUser)
          emit("close-modal", false)
        })
        .catch((err) => {
          if (err.response.status === 401) return
          
          const { message } = err?.response?.data?.error || {};
          Swal.fire({
            text:
              message !== null &&
                message !== "" &&
                typeof message !== "undefined"
                ? message
                : `Inconveniente en el sistema, intente más tarde.`,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        })
        .finally(() => (loading.value = false));
    }

    onMounted(() => {
      modal.value = new Modal(document.getElementById("myModalCredentials"), {})
    })

    watch(props.user, value => {
      currentUser.id = value.id
      currentUser.usuario = value.usuario
    })

    return {
      closedModal,
      currentUser,
      onlyNumberReg,
      onlyAlPhaNumeric,
      vuc,
      updated,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>