<template>
  <div class="modal" tabindex="-1" id="myModalUser">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Actualizar Usuario</b></h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="content-fluid">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="txtNombre" class="form-control-label">Nombre</label>
                    <input class="form-control" type="text" id="txtNombre" v-model="currentUser.nombre"
                      @keypress="onlyAlPhaNumericSpace" @change="onlyAlPhaNumericSpace" @copy="onlyAlPhaNumericSpace"
                      @paste="onlyAlPhaNumericSpace">
                    <div v-if="vuu.nombre.$error" class="invalid-feedback">
                      {{ vuu.nombre.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div v-if="currentUser.rolId != 2" class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="txtApellido" class="form-control-label">Apellido</label>
                    <input class="form-control" type="text" id="txtApellido" v-model="currentUser.apellido"
                      @keypress="onlyAlPhaNumericSpace" @change="onlyAlPhaNumericSpace" @copy="onlyAlPhaNumericSpace"
                      @paste="onlyAlPhaNumericSpace">
                    <div v-if="vuu.apellido.$error" class="invalid-feedback">
                      {{ vuu.apellido.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="txtDni" class="form-control-label">No. de Identificación</label>
                    <input class="form-control" type="text" id="txtDni" v-model="currentUser.documento"
                      @keypress="onlyNumberReg" @change="onlyNumberReg" @copy="onlyNumberReg" @paste="onlyNumberReg">
                    <div v-if="vuu.documento.$error" class="invalid-feedback">
                      {{ vuu.documento.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="txtCelular" class="form-control-label">Teléfono</label>
                    <input class="form-control" type="text" id="txtCelular" v-model="currentUser.celularUno"
                      @keypress="onlyNumberReg" @change="onlyNumberReg" @copy="onlyNumberReg" @paste="onlyNumberReg">
                    <div v-if="vuu.celularUno.$error" class="invalid-feedback">
                      {{ vuu.celularUno.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="txtCorreo" class="form-control-label">Correo</label>
                    <input class="form-control" type="text" id="txtCorreo" v-model="currentUser.emailCorporativo">
                    <div v-if="vuu.emailCorporativo.$error" class="invalid-feedback">
                      {{ vuu.emailCorporativo.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button :disabled="loading" type="button" class="btn btn-danger" @click="closedModal">
            Cancelar
          </button>
          <button :disabled="loading" type="button" class="btn btn-success" @click="updated">
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i> Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MockAdapter from 'axios-mock-adapter';

import { ref, watchEffect, toRefs, onMounted, reactive, computed } from 'vue';
import { Modal } from "bootstrap";
import { onlyNumberReg, onlyAlPhaNumericSpace, concat_ws } from "@/utils/functions";
import { maxLength, required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axiosInstance from "@/services/api";
import Swal from "sweetalert2";
import useEventsBus from '@/helpers/eventBus'

export default {
  name: "modal-update-user",

  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const { isOpen, user } = toRefs(props)

    const { emit: emitt } = useEventsBus()

    const modal = ref(null)
    const loading = ref(false)
    const currentUser = reactive(user.value)

    // const mock = new MockAdapter(axiosInstance);

    // mock.onPost().reply(200, true);

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show()
        if (!isOpen.value) modal.value.hide()
      }
    })

    const rules = computed(() => ({
      nombre: {
        required: helpers.withMessage("Campo requerido", required),
        maxLength: helpers.withMessage("Máximo 100 caracteres", maxLength(100)),
      },
      apellido: {
        required: helpers.withMessage("Campo requerido", (value) => currentUser.rolId == 2 || (currentUser.rolId != 2 && value.length > 0)),
        maxLength: helpers.withMessage("Máximo 100 caracteres", maxLength(100)),
      },
      documento: {
        required: helpers.withMessage("Campo requerido", required),
        maxLength: helpers.withMessage("Máximo 20 caracteres", maxLength(20)),
      },
      celularUno: {
        required: helpers.withMessage("Campo requerido", required),
        maxLength: helpers.withMessage("Máximo 10 caracteres", maxLength(10)),
      },
      emailCorporativo: {
        required: helpers.withMessage("Campo requerido", required),
        email: helpers.withMessage("Debe tener una estructura válida de correo electrónico", email),
      },
    }));

    const vuu = useVuelidate(rules, currentUser);

    const closedModal = () => {
      vuu.value.$reset()
      emit("close-modal", false)
    }

    const updated = async () => {
      vuu.value.$touch();

      if (vuu.value.$invalid) return false;

      loading.value = true

      const payload = {
        id: currentUser.id,
        nombre: currentUser.nombre,
        apellido: currentUser.apellido,
        dni: currentUser.documento,
        celularUno: currentUser.celularUno,
        emailCorporativo: currentUser.emailCorporativo,
        rol: currentUser.rolId
      }

      await axiosInstance
        .put(`/users/update/info`, payload)
        .then(() => {
          Swal.fire({
            title: "Actualización Exitosa",
            icon: "success",
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#82d616',
          })

          vuu.value.$reset()

          currentUser.nombreUsuario = concat_ws(' ', currentUser.nombre, currentUser.apellido)

          emitt('updated-success', currentUser)
          emit("close-modal", false)
        })
        .catch((err) => {
          if (err.response.status === 401) return
          
          const { message } = err?.response?.data?.error || {};
          Swal.fire({
            text:
              message !== null &&
                message !== "" &&
                typeof message !== "undefined"
                ? message
                : `Inconveniente en el sistema, intente más tarde.`,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        })
        .finally(() => (loading.value = false));
    }

    onMounted(() => {
      modal.value = new Modal(document.getElementById("myModalUser"), {})
    })

    return {
      closedModal,
      currentUser,
      onlyNumberReg,
      onlyAlPhaNumericSpace,
      vuu,
      updated,
      loading
    }
  }
}
</script>

<style lang="scss" scoped></style>