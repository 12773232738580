const auth = {
  state: {
    menus: [],
    user: {}
  },

  getters: {
    getMenus: (state) => state.menus,
    getUser: (state) => state.user
  },

  mutations: {
    menus: (state, payload) => {
      state.menus = payload;
    },
    user: (state, payload) => {
      state.user = payload;
    },
    init: (state) => {
      state.menus = [];
    },
  },

  actions: {
    setMenus: ({ commit }, payload) => {
      commit("menus", payload);
    },
    setInitState: ({ commit }, payload) => {
      commit("user", payload);
    },
    initState: ({ commit }) => {
      commit("init");
    },
  },
};

export default auth;
